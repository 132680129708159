export const loadLocalTimeStamp = () => {
  const LocalTimeStamp = localStorage.getItem('localTimeStamp');
  const unixtTmestamp = Math.floor(Date.now() / 1000);
  try {
    const parseLocalState = JSON.parse(LocalTimeStamp);
    if (parseLocalState === null) {
      localStorage.setItem('localTimeStamp', unixtTmestamp);
    }
    else if (parseLocalState) {
      var endTimeUnix = Math.floor(Date.now() / 1000);
      const currentTimeStampUnix = localStorage.getItem('localTimeStamp');
      var time = endTimeUnix - currentTimeStampUnix;
      if (time > 900) {
        localStorage.removeItem('localTimeStamp')
        setTimeout(function () { localStorage.removeItem('persist:root') }, 2000);
        localStorage.setItem('localTimeStamp', Math.floor(Date.now() / 1000));
      }
    }
  } catch (err) {
    console.log(err)
  }
}
