
const initState = {
  checkoutID: '',
}

const CHECKOUT_ID_SAVED = 'CHECKOUT_ID_SAVED';
const CHECKOUT_ID_REMOVED = 'CHECKOUT_ID_REMOVED';

export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHECKOUT_ID_SAVED:
      return {
        ...state,
        checkoutID: action.checkoutID
      }
    case CHECKOUT_ID_REMOVED:
      return {
        ...state,
        checkoutID: action.checkoutID
      }
    default:
      return state;
  }
}






