// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-cart-checkout-js": () => import("./../../../src/pages/cart-checkout.js" /* webpackChunkName: "component---src-pages-cart-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-article-page-template-js": () => import("./../../../src/templates/ArticlePageTemplate.js" /* webpackChunkName: "component---src-templates-article-page-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-video-page-template-js": () => import("./../../../src/templates/VideoPageTemplate.js" /* webpackChunkName: "component---src-templates-video-page-template-js" */)
}

