const CART_IS_OPEN = 'CART_IS_OPEN'
const CART_IS_CLOSED = 'CART_IS_CLOSED'

const initState = {
  isCartOpen: false
}


export default function reducer(state = initState, action) {
  switch (action.type) {
    case CART_IS_OPEN:
      return {
        ...state,
        isCartOpen: true
      }
    case CART_IS_CLOSED:
      return {
        ...state,
        isCartOpen: false
      }
    default:
      return state;
  }
}

export const CART_IS_OPEN_ACTION = () => {
  return {
    type: CART_IS_OPEN,
  }
}

export const CLOSE_CART_ACTION = () => {
  return {
    type: CART_IS_CLOSED,
  }
}



export const openCart = () => (dispatch, getState) => {
  try {
    dispatch(CART_IS_OPEN_ACTION())
  } catch (e) {
    console.log(e)
  };
};


export const closeCart = () => (dispatch, getState) => {
  try {
    dispatch(CLOSE_CART_ACTION())
  } catch (e) {
    console.log(e)
  };
};
