import { createStore as _createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';



import thunk from 'redux-thunk';
import logger from '../middleware/clientMiddleWare'
import checkout from '../modules/Checkout/checkout'
import product from '../modules/Product/products';

import cart from '../modules/Cart/cart'

const reducer = combineReducers({
  _product: product,
  _checkout: checkout,
  _cart: cart
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['_checkout', '_cart']
};


const persistedReducer = persistReducer(persistConfig, reducer)


export default function createStore(data, history, client) {
  // Sync dispatched route actions to the history
  const middleware = [logger(client), thunk];
  const composeEnhancers =
    typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      }) : compose;

  const finalCreateStore = composeEnhancers(applyMiddleware(...middleware))(_createStore);

  const store = finalCreateStore(persistedReducer, data);

  return store;
}
