
import { openCart } from '../Cart/cart'

const initState = {
  isCartOpen: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  isLoading: true,
  checkoutId: {}
}

const CLIENT_CREATED = 'CLIENT_CREATED'
const PRODUCTS_FOUND = 'PRODUCTS_FOUND'
const CHECKOUT_FOUND = 'CHECKOUT_FOUND'
const SHOP_FOUND = 'SHOP_FOUND'
const ADD_VARIANT_TO_CART = 'ADD_VARIANT_TO_CART'
const UPDATE_QUANTITY_IN_CART = 'UPDATE_QUANTITY_IN_CART'
const REMOVE_LINE_ITEM_IN_CART = 'REMOVE_LINE_ITEM_IN_CART'
const OPEN_CART = 'OPEN_CART'
const CLOSE_CART = 'CLOSE_CART'

// Reducer 
export default function reducer(state = initState, action) {
  switch (action.type) {
    case CLIENT_CREATED:
      return {
        ...state,
        client: action.client
      }
    case PRODUCTS_FOUND:
      return {
        ...state,
        products: action.products,
        isLoading: false,
      }
    case CHECKOUT_FOUND:
      return {
        ...state,
        checkout: action.checkout
      }
    case SHOP_FOUND:
      return {
        ...state,
        shop: action.shop
      }
    case ADD_VARIANT_TO_CART:
      return {
        ...state,
        isCartOpen: action.isCartOpen,
        checkout: action.checkout
      }
    case UPDATE_QUANTITY_IN_CART:
      return {
        ...state,
        checkout: action.checkout
      }
    case REMOVE_LINE_ITEM_IN_CART:
      return {
        ...state,
        checkout: action.checkout
      }
    case OPEN_CART:
      return {
        ...state,
        isCartOpen: true
      }
    case CLOSE_CART:
      return {
        ...state,
        isCartOpen: false
      }
    default:
      return state;
  }
}


export const ADD_VARIANT_TO_CART_ACTION = (isCartOpen, checkout) => {
  return {
    type: ADD_VARIANT_TO_CART,
    isCartOpen,
    checkout,
  }
}
export const UPDATE_QUANTITY_IN_CART_ACTION = (checkout) => {
  return {
    type: UPDATE_QUANTITY_IN_CART,
    checkout,
  }
}

export const REMOVE_LINE_ITEM_IN_CART_ACTION = (checkout) => {
  return {
    type: REMOVE_LINE_ITEM_IN_CART,
    checkout,
  }
}

// export const OPEN_CART_ACTION = () => {
//   return {
//     type: OPEN_CART,
//   }
// }

// export const CLOSE_CART_ACTION = () => {
//   return {
//     type: CLOSE_CART,
//   }
// }



// export const openCart = () => (dispatch, getState) => {
//   try {
//     dispatch(OPEN_CART_ACTION())
//   } catch (e) {
//     console.log(e)
//   };
// };

// export const closeCart = () => (dispatch, getState) => {
//   try {
//     dispatch(CLOSE_CART_ACTION())
//   } catch (e) {
//     console.log(e)
//   };
// };



export const addVariantToCart = (variantId, quantity) => (dispatch, getState) => {
  try {
    const state = getState()._product;
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = state.checkout.id;
    state.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      dispatch(openCart())
      return dispatch(ADD_VARIANT_TO_CART_ACTION(true, res));
    });
  } catch (e) {
    console.log(e)
  };
};



export const updateQuantityInCart = (lineItemId, quantity) => (dispatch, getState) => {
  try {
    const state = getState()._product;
    console.log(state)
    const checkoutId = state.checkout.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }]
    state.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      return dispatch(UPDATE_QUANTITY_IN_CART_ACTION(res));
    });
  } catch (e) {
    console.log(e)
  };
};

export const removeLineItemInCart = (lineItemId) => (dispatch, getState) => {
  try {
    const state = getState()._product
    console.log(state)
    const checkoutId = state.checkout.id;
    state.client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      return dispatch(REMOVE_LINE_ITEM_IN_CART_ACTION(res));
    });
  } catch (e) {
    console.log(e)
  };
};



