import React from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'

import { client } from './src/helpers/shopify'
import createStore from './src/state/createStore';
import { loadLocalTimeStamp } from './src/helpers/localStorage'
import 'whatwg-fetch';


export const wrapRootElement = ({ element }) => {
    loadLocalTimeStamp()
    const store = createStore()
    store.dispatch({ type: 'CLIENT_CREATED', client });
    client.product.fetchAll().then((res) => {
        let products = res.filter((oProduct) => {
            // Logic of where you wanna ship and don't wanna ship to certain locations
            return true // only show products that can be shipped to the respective location 
        });
        store.dispatch({ type: 'PRODUCTS_FOUND', products: products });
    });

    client.checkout.create().then((res) => {
        const checkoutIdFromLastSession = store.getState()._checkout.checkoutID;
        if (checkoutIdFromLastSession) {
            client.checkout.fetch(checkoutIdFromLastSession).then((checkout) => {
                return store.dispatch({ type: 'CHECKOUT_FOUND', checkout: checkout });
            });
        } else {
            store.dispatch({ type: 'CHECKOUT_ID_SAVED', checkoutID: res.id })
            return store.dispatch({ type: 'CHECKOUT_FOUND', checkout: res });
        }
    });

    client.shop.fetchInfo().then((res) => {
        store.dispatch({ type: 'SHOP_FOUND', shop: res });
    });

    const persistor = persistStore(store);

    const ConnectedRootElement = (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {element}
            </PersistGate>
        </Provider>
    )
    return ConnectedRootElement
}
